import Vue from "vue";
import VueRouter from "vue-router";
import Gate from "../gate"

Vue.use(VueRouter);
window.gate = new Gate(window.user);

if (gate.can("Update Information")) {
    var defaultProfile = "/profile/update-info";
} else if (gate.can("Generate New Key")) {
    var defaultProfile = "/profile/generate-key";
} else {
    var defaultProfile = "/profile/change-password";
}

const routes = [
    {
        component: () => import("../components/dashboard/dashboard.vue"),
        path: "/",
        name: "Dashboard",
        meta: { Dashboard: true },
    },
    {
        component: () => import("../components/reports/GeneralSummary.vue"),
        path: "/gen-summ",
        name: "GeneralSummary",
        meta: { GeneralSummary: true },
    },
    {
        component: () => import("../components/reports/Rpt.vue"),
        path: "/rpt",
        name: "RPT",
        meta: { RPT: true },
    },
    {
        component: () => import("../components/rpt/dataMatching.vue"),
        path: "/rpt-data-matching",
        name: "DataMatching",
        meta: { DataMatching: true },
    },
    {
        component: () => import("../components/trans/trans.vue"),
        path: "/trans",
        name: "Transactions",
        meta: { Transactions: true },
    },
    {
        component: () => import("../components/trans/bulk_trans.vue"),
        path: "/bulk-trans",
        name: "Bulk Transactions",
        meta: { BulkTransactions: true },
    },
    {
        component: () => import("../components/trans/details.vue"),
        path: "/tran-details/:id/:type/:or_type",
        name: "Transaction Details",
        meta: { TranDetails: true },
    },
    {
        component: () => import("../components/trans/bulk_trans_details.vue"),
        path: "/bulk-tran-details/:id",
        name: "Bulk Transaction Details",
        meta: { BulkTranDetails: true },
    },
    {
        component: () => import("../components/cashier/cashier.vue"),
        path: "/cashier",
        name: "Cashier",
        meta: { Cashier: true },
    },
    {
        component: () => import("../components/cashier/multiPayment.vue"),
        path: "/multi-payment",
        name: "Multiple Payment",
        meta: { multiPayment: true },
    },
    {
        component: () => import("../components/cashier/sht/sht.vue"),
        path: "/sht-list",
        name: "Sht",
        meta: { Sht: true },
    },
    {
        component: () => import("../components/cashier/ctc/ctc.vue"),
        path: "/ctc-list",
        name: "CTC",
        meta: { CTC: true },
    },
    {
        component: () => import("../components/collections/categorize.vue"),
        path: "/coll-accts",
        name: "Collection Accounts",
        meta: { CollectionAccounts: true },
    },
    {
        component: () => import("../components/collections/natureOfTax.vue"),
        path: "/nature-of-tax",
        name: "Nature of Tax",
        meta: { NatureOfTax: true },
    },
    {
        component: () => import("../components/collections/summColl.vue"),
        path: "/summ-of-coll",
        name: "Summary of Collections",
        meta: { summColl: true },
    },
    {
        component: () => import("../components/collections/Sma.vue"),
        path: "/main-accounts",
        name: "SMA",
        meta: { SMA: true },
    },
    {
        component: () => import("../components/collections/GFRCD.vue"),
        path: "/gf-rcd-trust-sef",
        name: "GF-RCD/TRUST/SEF",
        meta: { GFRCD: true },
    },
    {
        component: () => import("../components/employee/employee.vue"),
        path: "/employees",
        name: "EmployeeList",
        meta: { EmployeeList: true },
    },
    {
        component: () => import("../components/remittance/remittance.vue"),
        path: "/remittance",
        name: "Remittance",
        meta: { Remittance: true },
    },
    {
        component: () => import("../components/cancelled-or/cancelled-or.vue"),
        path: "/cancelled-or",
        name: "CancelledOR",
        meta: { CancelledOR: true },
    },
    {
        component: () => import("../components/or/or.vue"),
        path: "/or",
        name: "OR",
        meta: { OR: true },
    },
    {
        component: () => import("../components/brgy/Barangays.vue"),
        path: "/brgy-list",
        name: "Barangays",
        meta: { Barangays: true },
    },
    {
        component: () => import("../components/users/users.vue"),
        path: "/user-mgt",
        name: "UserManagement",
        meta: { UserManagement: true },
    },
    {
        component: () => import("../components/logs/logs.vue"),
        path: "/logs",
        name: "Logs",
        meta: { Logs: true },
    },
    {
        component: () => import("../components/depts/depts.vue"),
        path: "/department-list",
        name: "Departments",
        meta: { Departments: true },
    },
    {
        component: () => import("../components/profile/profile.vue"),
        path: "/profile",
        redirect: defaultProfile,
        name: "Profile",
        meta: { Profile: true },
        children: [
            {
                path: "update-info",
                component: () =>
                    import("../components/profile/update_info.vue"),
                meta: { UpdateInfo: true },
            },
            {
                path: "change-password",
                component: () =>
                    import("../components/profile/change_password.vue")
            },
            {
                path: "generate-key",
                component: () =>
                    import("../components/profile/generate_new_key.vue"),
                meta: { GenerateKey: true },
            },
        ]
    },
    {
        component: () => import("../components/rolesAndPermissions/rolesAndPermissions.vue"),
        path: "/roles-permissions",
        name: "RolesAndPermissions",
        meta: { RolesAndPermissions: true },
    },
    {
        component: () => import("../components/dashboard/dashboard.vue"),
        path: "*",
        meta: { Others: true },
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.Dashboard)) {
        if (gate.can('View Dashboard Analytics')) {
            next();
        } else {
            if (gate.can('Nature of Tax') ||
                gate.can('Summary Collection') ||
                gate.can('Summary of Main Accounts') ||
                gate.can('GF-RCD/TRUST/SEF') ||
                gate.can('RCD')) {
                next("/gen-summ");
            } else if (gate.can('Real Property Tax Collection') ||
                gate.can('Advance Real Property Tax Collection') ||
                gate.can('Barangay Shares') ||
                gate.can('Advance Barangay Shares') ||
                gate.can('Report on RPT (Basic / SEF)')) {
                next("/rpt");
            } else if (gate.can('RPT Data Sync')) {
                next("/rpt-data-matching");
            } else if (gate.can('Create Transaction')) {
                next("/cashier");
            } else if (gate.can('Multiple Transaction')) {
                next("/multi-payment");
            } else if (gate.can('View SHT List')) {
                next("/sht-list");
            } else if (gate.can('View CTC List')) {
                next("/ctc-list");
            } else if (gate.can('View Transaction List') || gate.can('View Transaction List Admin')) {
                next("/trans");
            } else if (gate.can('View Bulk Transaction List') || gate.can('View Bulk Transaction List Admin')) {
                next("/bulk-trans");
            } else if (gate.can('View Transaction Details')) {
                next("/tran-details/:id/:type/:or_type");
            } else if (gate.can('View Bulk Transaction Details')) {
                next("/bulk-tran-details/:id");
            } else if (gate.can('View Collection Names')) {
                next("/coll-accts");
            } else if (gate.can('View Nature of Tax List')) {
                next("/nature-of-tax");
            } else if (gate.can('View Summary of Collection List')) {
                next("/summ-of-coll");
            } else if (gate.can('View Main Account List')) {
                next("/main-accounts");
            } else if (gate.can('View GF-RCD/TRUST/SEF List')) {
                next("/gf-rcd-trust-sef");
            } else if (gate.can('View Employee List')) {
                next("/employees");
            } else if (gate.can('View Remittance') || gate.can('View Remittance Admin')) {
                next("/remittance");
            } else if (gate.can('View Cancelled OR List') || gate.can('View Cancelled OR List Admin')) {
                next("/cancelled-or");
            } else if (gate.can('View OR List')) {
                next("/or");
            } else if (gate.can('View Barangay List')) {
                next("/brgy-list");
            } else if (gate.can('View User List')) {
                next("/user-mgt");
            } else if (gate.can('View Log List')) {
                next("/logs");
            } else if (gate.can('View Department List')) {
                next("/department-list");
            } else if (gate.can('View Role List')) {
                next("/roles-permissions");
            } else {
                next("/profile");
            }
        }
    }
    else if (to.matched.some((record) => record.meta.GeneralSummary)) {
        if (gate.can('Nature of Tax') ||
            gate.can('Summary Collection') ||
            gate.can('Summary of Main Accounts') ||
            gate.can('GF-RCD/TRUST/SEF') ||
            gate.can('RCD')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.RPT)) {
        if (gate.can('Real Property Tax Collection') ||
            gate.can('Advance Real Property Tax Collection') ||
            gate.can('Barangay Shares') ||
            gate.can('Advance Barangay Shares') ||
            gate.can('Report on RPT (Basic / SEF)')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.DataMatching)) {
        if (gate.can('RPT Data Sync')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.Cashier)) {
        if (gate.can('Create Transaction')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.multiPayment)) {
        if (gate.can('Multiple Transaction')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.Sht)) {
        if (gate.can('View SHT List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.CTC)) {
        if (gate.can('View CTC List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.Transactions)) {
        if (gate.can('View Transaction List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.TranDetails)) {
        if (gate.can('View Transaction Details')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.BulkTranDetails)) {
        if (gate.can('View Bulk Transaction Details')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.CollectionAccounts)) {
        if (gate.can('View Collection Names')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.NatureOfTax)) {
        if (gate.can('View Nature of Tax List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.summColl)) {
        if (gate.can('View Summary of Collection List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.SMA)) {
        if (gate.can('View Main Account List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.GFRCD)) {
        if (gate.can('View GF-RCD/TRUST/SEF List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.EmployeeList)) {
        if (gate.can('View Employee List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.Remittance)) {
        if (gate.can('View Remittance')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.CancelledOR)) {
        if (gate.can('View Cancelled OR List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.OR)) {
        if (gate.can('View OR List')) {
            next();
        } else {
            next("/");
        }
    } else if (to.matched.some((record) => record.meta.Barangays)) {
        if (gate.can('View Barangay List')) {
            next();
        } else {
            next("/");
        }
    } else if (to.matched.some((record) => record.meta.UserManagement)) {
        if (gate.can('View User List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.Logs)) {
        if (gate.can('View Log List')) {
            next();
        } else {
            next("/");
        }
    }
    else if (to.matched.some((record) => record.meta.Departments)) {
        if (gate.can('View Department List')) {
            next();
        } else {
            next("/");
        }
    } else if (to.matched.some((record) => record.meta.Profile)) {
        if (gate.can('Update Information') || gate.can('Generate New Key')) {
            if (to.matched.some((record) => record.meta.UpdateInfo)) {
                if (gate.can('Update Information')) {
                    next();
                } else {
                    next("/");
                }
            } else if (to.matched.some((record) => record.meta.GenerateKey)) {
                if (gate.can('Generate New Key')) {
                    next();
                } else {
                    next("/");
                }
            } else {
                next();
            }
        } else {
            next();
        }
    } else if (to.matched.some((record) => record.meta.RolesAndPermissions)) {
        if (gate.can('View Role List')) {
            next();
        } else {
            next("/");
        }
    } else {
        next();
    }
});

export default router;