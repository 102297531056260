<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="7" xs="8" md="5" lg="4" xl="3">
          <v-card outlined>
            <v-row justify="center" class="text-center px-10 pt-5">
              <v-col>
                <v-img class="mx-5 mb-0" src="../images/rcms_logo.svg"></v-img>
              </v-col>
              <v-col>
                <v-img class="px-1 mt-n5" src="../images/rcms_title.svg"></v-img>
              </v-col>
            </v-row>
            <v-card-text class="text-center">
              <span class="text-center">
                Please enter the <strong>OTP (One Time Pin)</strong> generated on your
                Authenticator App. Ensure you submit the current one because it
                refreshes every <strong>30 seconds</strong>.
              </span>
              <v-form class="mt-2" ref="form" @keydown.native.enter.prevent="next" v-model="valid" lazy-validation>
                <v-otp-input length="6" v-model="form.one_time_password" :rules="rules.otp" hide-details></v-otp-input>
                <div class="text-left">
                  <small class="error--text">{{ text }}</small>
                </div>
                <v-btn class="primary mb-3 mt-8" large block dark :loading="loading" @click="next">
                  Login
                </v-btn>
              </v-form>
              <v-col cols="12" class="pb-0 d-flex justify-center">
                <v-btn class="primary--text" small text @click="close">
                  <v-icon left>mdi-chevron-left</v-icon>
                  Go back to login page
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
          <v-card-text class="text-center">
            <span>
              Copyright © {{ moment().format("YYYY") }} Pylon. All rights reserved.
            </span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    valid: true,
    snackbar: false,
    text: "",
    form: {
      one_time_password: "",
    },
    rules: {
      otp: [
        (v) => !!v || "OTP is required",
        // Adding more dynamic validation rules can be done here if needed
      ],
    },
  }),

  methods: {
    close() {
      axios
        .post("/logout")
        .then(() => {
          window.location.href = "/";
        })
        .catch(() => {
          window.location.href = "/";
        });

    },

    next() {
      this.loading = true;
      this.text = "";
      if (this.rules.otp[1]) {
        this.rules.otp.splice(1)
      }

      if (this.$refs.form.validate()) {
        axios
          .post("/2fa", this.form)
          .then(() => {
            window.location.reload();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status === 401 || error.response.status === 419) {
              window.location.href = "/";
            } else {
              this.text = "Error! Please re-try.";
              if (error.response.data.message) {
                this.text = error.response.data.message[0];
                this.$set(
                  this.rules,
                  "otp",
                  [
                    ...this.rules.otp,
                    (v) => false || error.response.data.message[0],
                  ]
                );
              }
              this.$refs.form.validate();
            }
          });
      } else {
        this.text = "OTP is required";
        this.loading = false;
      }
    },
  },
};
</script>
